<template>
  <div>

    <js-mind :values="mind" :options="options" v-if="mind.data.length>2" ref="jsMind" :height="mindHeight"></js-mind>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";

export default {
  data: function () {
    return {
      drawer: false,
      dialogVisible: false,
      direction: 'rtl',
      theme_value: '',
      nodeOption: {
        content: '',
        bgColor: '',
        fontColor: '',
        fontSize: '',
        fontWeight: '',
        fontStyle: ''
      },
      mindHeight: '1100px',
      mind: {
        meta: {
          name: 'metadata show',
          author: 'husir',
          version: '2.0'
        },
        format: 'node_array',
        data: [
          {id: "root", isroot: true, topic: "元数据"},
        ]
      },
      options: {
        container: 'jsmind_container', // [必选] 容器的ID
        editable: false, // [可选] 是否启用编辑
        theme: 'success' // [可选] 主题
      },
      formData: {
        id: '',
        mindCode: '',
        mindName: '',
        mindType: '',
        mindData: '',
        mindOptions: ''
      },
      mindOptions: {
        theme: ''
      },
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      color: 'rgba(255, 69, 0, 0.68)',
      predefineColors: [
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      isShow: false

    };
  },
//   mounted() {
//   },
  mounted() {
  this.instance = axios.create();
    this.instance({
      url: "/metadata/j_spring_security_check",
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
      },
      data: qs.stringify({
        j_username: "admin",
        j_password: "admin",
      }),
    }).then(() => {

      let mydata={};

      let number = 2;

      this.$http({
        url: "/metadata/api/atlas/v2/types/typedefs",
        method: "get",
        headers: {
          "Content-Type": null,
        },
      }).then((res) => {
        // console.log(res);
        res.data.classificationDefs.forEach((element) => {

               //当有属性的时候
          if(element.attributeDefs.length>0){
            //放入属性这个节点
            mydata["id"]=element.name+"attribute";
            mydata["topic"]="属性";
            mydata["parentid"]=element.name;
            mydata['background-color']='#ff0000';
            mydata['foreground-color']='#A9F5E1';
            this.mind.data.push(mydata)
         //   this.jm.set_node_color(element.name+"attribute", '#ff0000', '#A9F5E1')
            mydata = {};
            element.attributeDefs.forEach((att)=>{

              mydata["id"]=element.name+att.name;
              mydata["topic"]=att.name;
             mydata["parentid"]=element.name+"attribute";
              mydata['background-color']='#ff0000';
              mydata['foreground-color']='#A9F5E1';
              // mydata["parentid"]=element.name;
              this.mind.data.push(mydata)
              mydata = {};
            });
          }


          mydata["id"] = element.name;
          mydata["topic"] = element.name;


          if (element.superTypes[0]) {
            mydata["parentid"] = element.superTypes[0];
          } else {
            mydata["parentid"] = "root";
            if (number % 2 == 0) {
              mydata["direction"] = "right";
              number = number + 1;
              console.log(number)
            } else {
              mydata["direction"] = "left";
              number = number + 1;
            }

          }

          this.mind.data.push(mydata)
          mydata = {};


        });
  console.log("1111")
        console.log(this.mind)
        // this.isShow = true

      });






    })
    console.log(this.mind.data)

  },
  methods: {


    init() {
      this.mind.data[0].name = "text";
      console.log(this.mind.data[0].name);

      this.getGlossary();

    },
    // 获取
    getGlossary() {

     let mydata={};

      let number = 2;


      this.$http({
        //http://10.168.57.10:21000/api/atlas/v2/types/typedefs?type=classification&_=1638346364516
        url: "/metadata/api/atlas/v2/types/typedefs",
        method: "get",
        headers: {
          "Content-Type": null,
        },
      }).then((res) => {
        // console.log(res);
        res.data.classificationDefs.forEach((element) => {

          mydata["id"] = element.name;
          mydata["topic"] = element.name;

          if (element.superTypes[0]) {
            mydata["parentid"] = element.superTypes[0];
          } else {
            mydata["parentid"] = "root";
            if (number % 2 == 0) {
              mydata["direction"] = "right";
              number = number + 1;
              console.log(number)
            } else {
              mydata["direction"] = "left";
              number = number + 1;
            }

          }

          this.mind.data.push(mydata)
          mydata = {};


        });

       console.log(this.mind.data)
         this.isShow = true

      });
    },
  },
};
</script>

<style>
.header {
  height: 60px;
  background-color: #eee;
  border-bottom: solid 1px #aaa;
  z-index: 100;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  overflow: hidden;
  background-color: #f2f2f2;
  border-top: solid 1px #bbb;
}
</style>